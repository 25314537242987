<template>
    <div class="animation fadeIn">
        <b-row>
            <b-col sm="12">
                <b-card>
                    <b-form v-on:submit="submit">

                        <b-row>
                            <b-col sm="4">
                                <b-form-group>
                                    <label for="title">Title</label>
                                    <b-form-input v-model="category.CategoryTitle"></b-form-input>
                                </b-form-group>
                            </b-col>

                            <b-col sm="4">
                                <b-form-group>
                                    <label for="description">Description</label>
                                    <b-form-input v-model="category.CategoryDesc"></b-form-input>
                                </b-form-group>
                            </b-col>

                             <b-col sm="4">
                                <b-form-group>
                                    <label for="status">Status</label>
                                    <br>
                                    <c-switch class="mx-1" color="primary" v-model="category.CategoryIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />                                                      
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col sm="6">
                                <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { Switch as cSwitch } from '@coreui/vue'

export default {
    name: 'CreateProgrammingCategory',
    components: {
        cSwitch
    },
    data() {
        return {
            category: {
                CategoryTitle: '',
                CategoryDesc: '',
                CategoryIsActive: true
            },
            labelIcon: {
            dataOn: '\u2713',
            dataOff: '\u2715'
          },
        }
    },
    methods: {
        submit() {

        }
    }
}
</script>

<style>

</style>
